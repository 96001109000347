export const IoSocketStatus = () => import('../../node_modules/nuxt-socket-io/lib/components/SocketStatus.js' /* webpackChunkName: "components/io-socket-status" */).then(c => wrapFunctional(c.default || c))
export const CommonsAutoFormatTextField = () => import('../../components/Commons/AutoFormatTextField.vue' /* webpackChunkName: "components/commons-auto-format-text-field" */).then(c => wrapFunctional(c.default || c))
export const CommonsBankCard = () => import('../../components/Commons/BankCard.vue' /* webpackChunkName: "components/commons-bank-card" */).then(c => wrapFunctional(c.default || c))
export const CommonsBankCryptoCard = () => import('../../components/Commons/BankCryptoCard.vue' /* webpackChunkName: "components/commons-bank-crypto-card" */).then(c => wrapFunctional(c.default || c))
export const CommonsChangeLanguageLoading = () => import('../../components/Commons/ChangeLanguageLoading.vue' /* webpackChunkName: "components/commons-change-language-loading" */).then(c => wrapFunctional(c.default || c))
export const CommonsCopyButton = () => import('../../components/Commons/CopyButton.vue' /* webpackChunkName: "components/commons-copy-button" */).then(c => wrapFunctional(c.default || c))
export const CommonsCustomQrCode = () => import('../../components/Commons/CustomQrCode.vue' /* webpackChunkName: "components/commons-custom-qr-code" */).then(c => wrapFunctional(c.default || c))
export const CommonsDatePicker = () => import('../../components/Commons/DatePicker.vue' /* webpackChunkName: "components/commons-date-picker" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadBanner = () => import('../../components/Commons/DownloadBanner.vue' /* webpackChunkName: "components/commons-download-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadByOS = () => import('../../components/Commons/DownloadByOS.vue' /* webpackChunkName: "components/commons-download-by-o-s" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadByOs = () => import('../../components/Commons/DownloadByOs.vue' /* webpackChunkName: "components/commons-download-by-os" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadPage = () => import('../../components/Commons/DownloadPage.vue' /* webpackChunkName: "components/commons-download-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsFilterForm = () => import('../../components/Commons/FilterForm.vue' /* webpackChunkName: "components/commons-filter-form" */).then(c => wrapFunctional(c.default || c))
export const CommonsGameIframeEmbed = () => import('../../components/Commons/GameIframeEmbed.vue' /* webpackChunkName: "components/commons-game-iframe-embed" */).then(c => wrapFunctional(c.default || c))
export const CommonsGameLoadFailed = () => import('../../components/Commons/GameLoadFailed.vue' /* webpackChunkName: "components/commons-game-load-failed" */).then(c => wrapFunctional(c.default || c))
export const CommonsInviteAndEarnBanner = () => import('../../components/Commons/InviteAndEarnBanner.vue' /* webpackChunkName: "components/commons-invite-and-earn-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonsInviteAndEarnBannerDesktop = () => import('../../components/Commons/InviteAndEarnBannerDesktop.vue' /* webpackChunkName: "components/commons-invite-and-earn-banner-desktop" */).then(c => wrapFunctional(c.default || c))
export const CommonsLanguageSwitcher = () => import('../../components/Commons/LanguageSwitcher.vue' /* webpackChunkName: "components/commons-language-switcher" */).then(c => wrapFunctional(c.default || c))
export const CommonsLoading = () => import('../../components/Commons/Loading.vue' /* webpackChunkName: "components/commons-loading" */).then(c => wrapFunctional(c.default || c))
export const CommonsMaintenance = () => import('../../components/Commons/Maintenance.vue' /* webpackChunkName: "components/commons-maintenance" */).then(c => wrapFunctional(c.default || c))
export const CommonsNoSupportPage = () => import('../../components/Commons/NoSupportPage.vue' /* webpackChunkName: "components/commons-no-support-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsNotFoundPage = () => import('../../components/Commons/NotFoundPage.vue' /* webpackChunkName: "components/commons-not-found-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsNuxtImage = () => import('../../components/Commons/NuxtImage.vue' /* webpackChunkName: "components/commons-nuxt-image" */).then(c => wrapFunctional(c.default || c))
export const CommonsPhoneInput = () => import('../../components/Commons/PhoneInput.vue' /* webpackChunkName: "components/commons-phone-input" */).then(c => wrapFunctional(c.default || c))
export const CommonsPopup = () => import('../../components/Commons/Popup.vue' /* webpackChunkName: "components/commons-popup" */).then(c => wrapFunctional(c.default || c))
export const CommonsSkeletonLoader = () => import('../../components/Commons/SkeletonLoader.vue' /* webpackChunkName: "components/commons-skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const CommonsThemeMode = () => import('../../components/Commons/ThemeMode.vue' /* webpackChunkName: "components/commons-theme-mode" */).then(c => wrapFunctional(c.default || c))
export const Theme2AgentFloatingIcon = () => import('../../components/Theme2/AgentFloatingIcon.vue' /* webpackChunkName: "components/theme2-agent-floating-icon" */).then(c => wrapFunctional(c.default || c))
export const Theme2Announcement = () => import('../../components/Theme2/Announcement.vue' /* webpackChunkName: "components/theme2-announcement" */).then(c => wrapFunctional(c.default || c))
export const Theme2AvatarIcon = () => import('../../components/Theme2/AvatarIcon.vue' /* webpackChunkName: "components/theme2-avatar-icon" */).then(c => wrapFunctional(c.default || c))
export const Theme2BetStatus = () => import('../../components/Theme2/BetStatus.vue' /* webpackChunkName: "components/theme2-bet-status" */).then(c => wrapFunctional(c.default || c))
export const Theme2ClaimStatus = () => import('../../components/Theme2/ClaimStatus.vue' /* webpackChunkName: "components/theme2-claim-status" */).then(c => wrapFunctional(c.default || c))
export const Theme2ConfirmDepositDialog = () => import('../../components/Theme2/ConfirmDepositDialog.vue' /* webpackChunkName: "components/theme2-confirm-deposit-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme2DepositBanner = () => import('../../components/Theme2/DepositBanner.vue' /* webpackChunkName: "components/theme2-deposit-banner" */).then(c => wrapFunctional(c.default || c))
export const Theme2FloatingIcon1 = () => import('../../components/Theme2/FloatingIcon1.vue' /* webpackChunkName: "components/theme2-floating-icon1" */).then(c => wrapFunctional(c.default || c))
export const Theme2FloatingIcon2 = () => import('../../components/Theme2/FloatingIcon2.vue' /* webpackChunkName: "components/theme2-floating-icon2" */).then(c => wrapFunctional(c.default || c))
export const Theme2HoldBalanceDialog = () => import('../../components/Theme2/HoldBalanceDialog.vue' /* webpackChunkName: "components/theme2-hold-balance-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme2ImageBackground = () => import('../../components/Theme2/ImageBackground.vue' /* webpackChunkName: "components/theme2-image-background" */).then(c => wrapFunctional(c.default || c))
export const Theme2OnHoldBalanceCard = () => import('../../components/Theme2/OnHoldBalanceCard.vue' /* webpackChunkName: "components/theme2-on-hold-balance-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2PlayerIdValidationCard = () => import('../../components/Theme2/PlayerIdValidationCard.vue' /* webpackChunkName: "components/theme2-player-id-validation-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2ProfileLayout = () => import('../../components/Theme2/ProfileLayout.vue' /* webpackChunkName: "components/theme2-profile-layout" */).then(c => wrapFunctional(c.default || c))
export const Theme2TransactionStatus = () => import('../../components/Theme2/TransactionStatus.vue' /* webpackChunkName: "components/theme2-transaction-status" */).then(c => wrapFunctional(c.default || c))
export const Theme2YoutubeEmbed = () => import('../../components/Theme2/YoutubeEmbed.vue' /* webpackChunkName: "components/theme2-youtube-embed" */).then(c => wrapFunctional(c.default || c))
export const CommonsPicesCircle = () => import('../../components/Commons/Pices/Circle.vue' /* webpackChunkName: "components/commons-pices-circle" */).then(c => wrapFunctional(c.default || c))
export const CommonsPicesStraight = () => import('../../components/Commons/Pices/Straight.vue' /* webpackChunkName: "components/commons-pices-straight" */).then(c => wrapFunctional(c.default || c))
export const CommonsFinanceDisplayBankQr = () => import('../../components/Commons/finance/DisplayBankQr.vue' /* webpackChunkName: "components/commons-finance-display-bank-qr" */).then(c => wrapFunctional(c.default || c))
export const Theme2ArticleContentComponent = () => import('../../components/Theme2/Article/ContentComponent.vue' /* webpackChunkName: "components/theme2-article-content-component" */).then(c => wrapFunctional(c.default || c))
export const Theme2ArticleNoContentAvailable = () => import('../../components/Theme2/Article/NoContentAvailable.vue' /* webpackChunkName: "components/theme2-article-no-content-available" */).then(c => wrapFunctional(c.default || c))
export const Theme2ComponentsActiveBonus = () => import('../../components/Theme2/Components/ActiveBonus.vue' /* webpackChunkName: "components/theme2-components-active-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme2ComponentsClaimBonusButton = () => import('../../components/Theme2/Components/ClaimBonusButton.vue' /* webpackChunkName: "components/theme2-components-claim-bonus-button" */).then(c => wrapFunctional(c.default || c))
export const Theme2ComponentsClaimPromoCodeDialog = () => import('../../components/Theme2/Components/ClaimPromoCodeDialog.vue' /* webpackChunkName: "components/theme2-components-claim-promo-code-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme2ComponentsExchangeRate = () => import('../../components/Theme2/Components/ExchangeRate.vue' /* webpackChunkName: "components/theme2-components-exchange-rate" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopBankAccountCard = () => import('../../components/Theme2/Desktop/BankAccountCard.vue' /* webpackChunkName: "components/theme2-desktop-bank-account-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopBankTransferStepper = () => import('../../components/Theme2/Desktop/BankTransferStepper.vue' /* webpackChunkName: "components/theme2-desktop-bank-transfer-stepper" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopCashier = () => import('../../components/Theme2/Desktop/Cashier.vue' /* webpackChunkName: "components/theme2-desktop-cashier" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopDepositStep = () => import('../../components/Theme2/Desktop/DepositStep.vue' /* webpackChunkName: "components/theme2-desktop-deposit-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopFeaturedContent1 = () => import('../../components/Theme2/Desktop/FeaturedContent1.vue' /* webpackChunkName: "components/theme2-desktop-featured-content1" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopFeaturedContent2 = () => import('../../components/Theme2/Desktop/FeaturedContent2.vue' /* webpackChunkName: "components/theme2-desktop-featured-content2" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopFeaturedContent3 = () => import('../../components/Theme2/Desktop/FeaturedContent3.vue' /* webpackChunkName: "components/theme2-desktop-featured-content3" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopFooter = () => import('../../components/Theme2/Desktop/Footer.vue' /* webpackChunkName: "components/theme2-desktop-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopForceToAddBankAndWallet = () => import('../../components/Theme2/Desktop/ForceToAddBankAndWallet.vue' /* webpackChunkName: "components/theme2-desktop-force-to-add-bank-and-wallet" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopHomeSlider = () => import('../../components/Theme2/Desktop/HomeSlider.vue' /* webpackChunkName: "components/theme2-desktop-home-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopInterRegisterForm = () => import('../../components/Theme2/Desktop/InterRegisterForm.vue' /* webpackChunkName: "components/theme2-desktop-inter-register-form" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopJackpotContent = () => import('../../components/Theme2/Desktop/JackpotContent.vue' /* webpackChunkName: "components/theme2-desktop-jackpot-content" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopLoginForm = () => import('../../components/Theme2/Desktop/LoginForm.vue' /* webpackChunkName: "components/theme2-desktop-login-form" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopLotteryWinnerCount = () => import('../../components/Theme2/Desktop/LotteryWinnerCount.vue' /* webpackChunkName: "components/theme2-desktop-lottery-winner-count" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopLotteryWinners = () => import('../../components/Theme2/Desktop/LotteryWinners.vue' /* webpackChunkName: "components/theme2-desktop-lottery-winners" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopMainCard = () => import('../../components/Theme2/Desktop/MainCard.vue' /* webpackChunkName: "components/theme2-desktop-main-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopMenuBar = () => import('../../components/Theme2/Desktop/MenuBar.vue' /* webpackChunkName: "components/theme2-desktop-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopNavBar = () => import('../../components/Theme2/Desktop/NavBar.vue' /* webpackChunkName: "components/theme2-desktop-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfileMainSection = () => import('../../components/Theme2/Desktop/ProfileMainSection.vue' /* webpackChunkName: "components/theme2-desktop-profile-main-section" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopPromotionCard = () => import('../../components/Theme2/Desktop/PromotionCard.vue' /* webpackChunkName: "components/theme2-desktop-promotion-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopPromotionContent = () => import('../../components/Theme2/Desktop/PromotionContent.vue' /* webpackChunkName: "components/theme2-desktop-promotion-content" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopRecentWinners = () => import('../../components/Theme2/Desktop/RecentWinners.vue' /* webpackChunkName: "components/theme2-desktop-recent-winners" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopRegisterForm = () => import('../../components/Theme2/Desktop/RegisterForm.vue' /* webpackChunkName: "components/theme2-desktop-register-form" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopSubMenu = () => import('../../components/Theme2/Desktop/SubMenu.vue' /* webpackChunkName: "components/theme2-desktop-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopTopWinnerUnluckyWinner = () => import('../../components/Theme2/Desktop/TopWinnerUnluckyWinner.vue' /* webpackChunkName: "components/theme2-desktop-top-winner-unlucky-winner" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopVideoContent1And2 = () => import('../../components/Theme2/Desktop/VideoContent1And2.vue' /* webpackChunkName: "components/theme2-desktop-video-content1-and2" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopWithdrawStep = () => import('../../components/Theme2/Desktop/WithdrawStep.vue' /* webpackChunkName: "components/theme2-desktop-withdraw-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDepositStep = () => import('../../components/Theme2/Mobile/DepositStep.vue' /* webpackChunkName: "components/theme2-mobile-deposit-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileFeaturedContent1 = () => import('../../components/Theme2/Mobile/FeaturedContent1.vue' /* webpackChunkName: "components/theme2-mobile-featured-content1" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileFeaturedContent2 = () => import('../../components/Theme2/Mobile/FeaturedContent2.vue' /* webpackChunkName: "components/theme2-mobile-featured-content2" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileFeaturedContent3 = () => import('../../components/Theme2/Mobile/FeaturedContent3.vue' /* webpackChunkName: "components/theme2-mobile-featured-content3" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileFooterComponent = () => import('../../components/Theme2/Mobile/FooterComponent.vue' /* webpackChunkName: "components/theme2-mobile-footer-component" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileForceToAddBankAndWallet = () => import('../../components/Theme2/Mobile/ForceToAddBankAndWallet.vue' /* webpackChunkName: "components/theme2-mobile-force-to-add-bank-and-wallet" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileGameHistory = () => import('../../components/Theme2/Mobile/GameHistory.vue' /* webpackChunkName: "components/theme2-mobile-game-history" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileGameMenu = () => import('../../components/Theme2/Mobile/GameMenu.vue' /* webpackChunkName: "components/theme2-mobile-game-menu" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileGameSlider = () => import('../../components/Theme2/Mobile/GameSlider.vue' /* webpackChunkName: "components/theme2-mobile-game-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileHomeSlider = () => import('../../components/Theme2/Mobile/HomeSlider.vue' /* webpackChunkName: "components/theme2-mobile-home-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileInterRegisterForm = () => import('../../components/Theme2/Mobile/InterRegisterForm.vue' /* webpackChunkName: "components/theme2-mobile-inter-register-form" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileJackpotContent = () => import('../../components/Theme2/Mobile/JackpotContent.vue' /* webpackChunkName: "components/theme2-mobile-jackpot-content" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileLoginForm = () => import('../../components/Theme2/Mobile/LoginForm.vue' /* webpackChunkName: "components/theme2-mobile-login-form" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileOnly = () => import('../../components/Theme2/Mobile/MobileOnly.vue' /* webpackChunkName: "components/theme2-mobile-only" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileMyHistory = () => import('../../components/Theme2/Mobile/MyHistory.vue' /* webpackChunkName: "components/theme2-mobile-my-history" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileNavBar = () => import('../../components/Theme2/Mobile/NavBar.vue' /* webpackChunkName: "components/theme2-mobile-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobilePlayerProfile = () => import('../../components/Theme2/Mobile/PlayerProfile.vue' /* webpackChunkName: "components/theme2-mobile-player-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobilePromotionContent = () => import('../../components/Theme2/Mobile/PromotionContent.vue' /* webpackChunkName: "components/theme2-mobile-promotion-content" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileReferFriend = () => import('../../components/Theme2/Mobile/ReferFriend.vue' /* webpackChunkName: "components/theme2-mobile-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileRegisterForm = () => import('../../components/Theme2/Mobile/RegisterForm.vue' /* webpackChunkName: "components/theme2-mobile-register-form" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileTitleAlert = () => import('../../components/Theme2/Mobile/TitleAlert.vue' /* webpackChunkName: "components/theme2-mobile-title-alert" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileVideoContent1And2 = () => import('../../components/Theme2/Mobile/VideoContent1And2.vue' /* webpackChunkName: "components/theme2-mobile-video-content1-and2" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileWithdrawStep = () => import('../../components/Theme2/Mobile/WithdrawStep.vue' /* webpackChunkName: "components/theme2-mobile-withdraw-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopFloatingIconsRankingButton = () => import('../../components/Theme2/Desktop/FloatingIcons/RankingButton.vue' /* webpackChunkName: "components/theme2-desktop-floating-icons-ranking-button" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopNavMessageDropdown = () => import('../../components/Theme2/Desktop/Nav/MessageDropdown.vue' /* webpackChunkName: "components/theme2-desktop-nav-message-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopNavProfileDropdown = () => import('../../components/Theme2/Desktop/Nav/ProfileDropdown.vue' /* webpackChunkName: "components/theme2-desktop-nav-profile-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopPagesProfilePage = () => import('../../components/Theme2/Desktop/Pages/ProfilePage.vue' /* webpackChunkName: "components/theme2-desktop-pages-profile-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesAddBankAccountInternational = () => import('../../components/Theme2/Desktop/Profiles/AddBankAccountInternational.vue' /* webpackChunkName: "components/theme2-desktop-profiles-add-bank-account-international" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesAddBankAccountLao = () => import('../../components/Theme2/Desktop/Profiles/AddBankAccountLao.vue' /* webpackChunkName: "components/theme2-desktop-profiles-add-bank-account-lao" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesBankAccountCard = () => import('../../components/Theme2/Desktop/Profiles/BankAccountCard.vue' /* webpackChunkName: "components/theme2-desktop-profiles-bank-account-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesBankAddEmptyCard = () => import('../../components/Theme2/Desktop/Profiles/BankAddEmptyCard.vue' /* webpackChunkName: "components/theme2-desktop-profiles-bank-add-empty-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesBankDetail = () => import('../../components/Theme2/Desktop/Profiles/BankDetail.vue' /* webpackChunkName: "components/theme2-desktop-profiles-bank-detail" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesCryptoEmptyCard = () => import('../../components/Theme2/Desktop/Profiles/CryptoEmptyCard.vue' /* webpackChunkName: "components/theme2-desktop-profiles-crypto-empty-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesCryptoWalletCard = () => import('../../components/Theme2/Desktop/Profiles/CryptoWalletCard.vue' /* webpackChunkName: "components/theme2-desktop-profiles-crypto-wallet-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositPage = () => import('../../components/Theme2/Desktop/Profiles/DepositPage.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposit-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesGameHistory = () => import('../../components/Theme2/Desktop/Profiles/GameHistory.vue' /* webpackChunkName: "components/theme2-desktop-profiles-game-history" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesMyBonus = () => import('../../components/Theme2/Desktop/Profiles/MyBonus.vue' /* webpackChunkName: "components/theme2-desktop-profiles-my-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesMyHistory = () => import('../../components/Theme2/Desktop/Profiles/MyHistory.vue' /* webpackChunkName: "components/theme2-desktop-profiles-my-history" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesMyMessage = () => import('../../components/Theme2/Desktop/Profiles/MyMessage.vue' /* webpackChunkName: "components/theme2-desktop-profiles-my-message" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesMyProfile = () => import('../../components/Theme2/Desktop/Profiles/MyProfile.vue' /* webpackChunkName: "components/theme2-desktop-profiles-my-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesNoData = () => import('../../components/Theme2/Desktop/Profiles/NoData.vue' /* webpackChunkName: "components/theme2-desktop-profiles-no-data" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesPlayerBankCard = () => import('../../components/Theme2/Desktop/Profiles/PlayerBankCard.vue' /* webpackChunkName: "components/theme2-desktop-profiles-player-bank-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesReferFriend = () => import('../../components/Theme2/Desktop/Profiles/ReferFriend.vue' /* webpackChunkName: "components/theme2-desktop-profiles-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesResetPassword = () => import('../../components/Theme2/Desktop/Profiles/ResetPassword.vue' /* webpackChunkName: "components/theme2-desktop-profiles-reset-password" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesWithdrawalPage = () => import('../../components/Theme2/Desktop/Profiles/WithdrawalPage.vue' /* webpackChunkName: "components/theme2-desktop-profiles-withdrawal-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDepositsBankTransfer = () => import('../../components/Theme2/Mobile/Deposits/BankTransfer.vue' /* webpackChunkName: "components/theme2-mobile-deposits-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDepositsCircleStepper = () => import('../../components/Theme2/Mobile/Deposits/CircleStepper.vue' /* webpackChunkName: "components/theme2-mobile-deposits-circle-stepper" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDepositsCryptoPayment = () => import('../../components/Theme2/Mobile/Deposits/CryptoPayment.vue' /* webpackChunkName: "components/theme2-mobile-deposits-crypto-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDepositsQRTransfer = () => import('../../components/Theme2/Mobile/Deposits/QRTransfer.vue' /* webpackChunkName: "components/theme2-mobile-deposits-q-r-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDepositsThirdPartyPayment = () => import('../../components/Theme2/Mobile/Deposits/ThirdPartyPayment.vue' /* webpackChunkName: "components/theme2-mobile-deposits-third-party-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDisplaysGameBlock = () => import('../../components/Theme2/Mobile/Displays/GameBlock.vue' /* webpackChunkName: "components/theme2-mobile-displays-game-block" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobilePagesDepositPage = () => import('../../components/Theme2/Mobile/Pages/DepositPage.vue' /* webpackChunkName: "components/theme2-mobile-pages-deposit-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobilePagesGamePage = () => import('../../components/Theme2/Mobile/Pages/GamePage.vue' /* webpackChunkName: "components/theme2-mobile-pages-game-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobilePagesWithdrawalPage = () => import('../../components/Theme2/Mobile/Pages/WithdrawalPage.vue' /* webpackChunkName: "components/theme2-mobile-pages-withdrawal-page" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileProfilesBankAccountCard = () => import('../../components/Theme2/Mobile/Profiles/BankAccountCard.vue' /* webpackChunkName: "components/theme2-mobile-profiles-bank-account-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileProfilesCryptoWalletCard = () => import('../../components/Theme2/Mobile/Profiles/CryptoWalletCard.vue' /* webpackChunkName: "components/theme2-mobile-profiles-crypto-wallet-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileProfilesMyBank = () => import('../../components/Theme2/Mobile/Profiles/MyBank.vue' /* webpackChunkName: "components/theme2-mobile-profiles-my-bank" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileProfilesMyCrypto = () => import('../../components/Theme2/Mobile/Profiles/MyCrypto.vue' /* webpackChunkName: "components/theme2-mobile-profiles-my-crypto" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileWithdrawalsBankTransfer = () => import('../../components/Theme2/Mobile/Withdrawals/BankTransfer.vue' /* webpackChunkName: "components/theme2-mobile-withdrawals-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileWithdrawalsCryptoPayment = () => import('../../components/Theme2/Mobile/Withdrawals/CryptoPayment.vue' /* webpackChunkName: "components/theme2-mobile-withdrawals-crypto-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositsBankTransfer = () => import('../../components/Theme2/Desktop/Profiles/Deposits/BankTransfer.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposits-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositsCryptoPayment = () => import('../../components/Theme2/Desktop/Profiles/Deposits/CryptoPayment.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposits-crypto-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositsQRTransfer = () => import('../../components/Theme2/Desktop/Profiles/Deposits/QRTransfer.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposits-q-r-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositsThirdPartyPayment = () => import('../../components/Theme2/Desktop/Profiles/Deposits/ThirdPartyPayment.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposits-third-party-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesWithdrawalBankTransfer = () => import('../../components/Theme2/Desktop/Profiles/Withdrawal/BankTransfer.vue' /* webpackChunkName: "components/theme2-desktop-profiles-withdrawal-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesWithdrawalCryptoTransfer = () => import('../../components/Theme2/Desktop/Profiles/Withdrawal/CryptoTransfer.vue' /* webpackChunkName: "components/theme2-desktop-profiles-withdrawal-crypto-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesWithdrawalDoneStep = () => import('../../components/Theme2/Desktop/Profiles/Withdrawal/DoneStep.vue' /* webpackChunkName: "components/theme2-desktop-profiles-withdrawal-done-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileDepositsSteppersFirstStep = () => import('../../components/Theme2/Mobile/Deposits/Steppers/FirstStep.vue' /* webpackChunkName: "components/theme2-mobile-deposits-steppers-first-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2MobileWithdrawalsSteppersFirstStep = () => import('../../components/Theme2/Mobile/Withdrawals/Steppers/FirstStep.vue' /* webpackChunkName: "components/theme2-mobile-withdrawals-steppers-first-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositsSteppersPlayerBankCard = () => import('../../components/Theme2/Desktop/Profiles/Deposits/Steppers/PlayerBankCard.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposits-steppers-player-bank-card" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositsSteppersTransferFirstStep = () => import('../../components/Theme2/Desktop/Profiles/Deposits/Steppers/TransferFirstStep.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposits-steppers-transfer-first-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositsSteppersTransferForthStep = () => import('../../components/Theme2/Desktop/Profiles/Deposits/Steppers/TransferForthStep.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposits-steppers-transfer-forth-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositsSteppersTransferSecondStep = () => import('../../components/Theme2/Desktop/Profiles/Deposits/Steppers/TransferSecondStep.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposits-steppers-transfer-second-step" */).then(c => wrapFunctional(c.default || c))
export const Theme2DesktopProfilesDepositsSteppersTransferThirdStep = () => import('../../components/Theme2/Desktop/Profiles/Deposits/Steppers/TransferThirdStep.vue' /* webpackChunkName: "components/theme2-desktop-profiles-deposits-steppers-transfer-third-step" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
